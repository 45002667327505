import { Placement } from "@popperjs/core";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { toAbsoluteUrl } from "../_oxapay/helpers";

interface props {
  tooltipPlacement?: string;
}

export const PayPagesFooter = ({ tooltipPlacement = "bottom" }: props) => {
  return (
    <div>
      <span className="text-dark fs-7 me-2">Powered by</span>
      <a href="https://oxapay.com/" target="_blank" rel="noreferrer">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          placement={tooltipPlacement as Placement}
          overlay={
            <Popover>
              <Popover.Header>Powered by oxapay.com</Popover.Header>
              <Popover.Body>
                OxaPay is a crypto payment gateway that makes it easy to accept
                crypto for webmasters, merchants, and telegram channels and
                makes it useful for all.
              </Popover.Body>
            </Popover>
          }
        >
          <span>
            <img
              src={toAbsoluteUrl(`/media/logos/demo3.svg`)}
              alt="OxaPay logo"
              height="15px"
              className="theme-light-show"
            />
            <img
              src={toAbsoluteUrl(`/media/logos/demo3-dark.svg`)}
              alt="OxaPay logo"
              height="15px"
              className="theme-dark-show"
            />
          </span>
        </OverlayTrigger>
      </a>
    </div>
  );
};
